<template>
  <div>
    <header class="header" :class="{ bgchange: bgChange }">
      <div class="container">
        <router-link to="/">
          <h1 class="logo">U-PAY</h1>
        </router-link>
        <nav class="nav">
          <ul class="menu hidden-xs-only">
            <li class="menu-item" v-for="item in nav" :key="item.name">
              <router-link class="menu-item-link" :id="item.to" :to="item.to">{{
                $t(`Layout['${item.name}']`)
              }}</router-link>
              <!-- <transition name="fade"> -->
              <div
                v-show="
                  (item.to !== '/' && $route.path.includes(item.to)) ||
                  ($route.path === '/' && item.to === '/')
                "
                class="nav-line"
              ></div>
              <!-- </transition> -->
              <ul class="submenu" v-if="item.children">
                <li
                  class="submenu-item"
                  v-for="subItem in item.children"
                  :key="subItem.name"
                >
                  <router-link :to="subItem.to">{{
                    $t(`Layout['${subItem.name}']`)
                  }}</router-link>
                </li>
              </ul>
            </li>
          </ul>
          <div class="buttons">
            <button @click="$router.push('/search')" class="hidden-xs-only">
              {{ $t("Layout['搜寻']") }}
            </button>
            <el-dropdown
              class="changeLang"
              trigger="click"
              @command="changeLange"
            >
              <span class="el-dropdown-link">
                {{ lang }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="zh">中文</el-dropdown-item>
                <el-dropdown-item command="en">English</el-dropdown-item>
                <el-dropdown-item command="kh">ខ្មែរ</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <i
              class="menu-icon el-icon-menu show-md-and-down"
              @click="menuShow = true"
            ></i>
          </div>
        </nav>
      </div>
      <!-- 侧边栏 -->
      <el-drawer
        class="menu-list"
        :with-header="false"
        :visible.sync="menuShow"
        direction="rtl"
        append-to-body
      >
        <el-menu
          :default-active="$route.path"
          :router="true"
          @select="menuShow = false"
          class="el-menu-vertical-demo"
        >
          <div v-for="item in nav" :key="item.name">
            <el-submenu :index="item.to" v-if="item.children">
              <template slot="title">
                <span>{{ $t(`Layout['${item.name}']`) }}</span>
              </template>
              <el-menu-item-group>
                <el-menu-item
                  v-for="subItem in item.children"
                  :key="subItem.name"
                  :route="subItem.to"
                  index="subItem.name"
                  >{{ $t(`Layout['${subItem.name}']`) }}</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
            <el-menu-item :route="item.to" :index="item.to" v-else>
              <span slot="title">{{ $t(`Layout['${item.name}']`) }}</span>
            </el-menu-item>
          </div>
          <div>
            <el-menu-item route="/search" index="搜寻">
              <span slot="title">{{ $t(`Layout['搜寻']`) }}</span>
            </el-menu-item>
          </div>
        </el-menu>
      </el-drawer>
    </header>
    <router-view />
    <footer class="footer">
      <div class="container">
        <div class="left-box">
          <div class="footer-logo">
            <img src="../assets/image/顶部UPAY-logo@2x.png" alt="U-PAY" />
          </div>
          <div class="left-box-list">
            <ul v-for="(list, i) in footerLeftList" :key="i">
              <li v-for="item in list" :key="item.name">
                <router-link :to="item.to">{{
                  $t(`Layout['${item.name}']`)
                }}</router-link>
              </li>
              <li v-if="i === 1" @click="showMapDialog">
                <a style="cursor: pointer">
                  {{ $t(`Layout['${"充值机位置"}']`) }}
                </a>
              </li>
            </ul>
            <ul>
              <template v-if="$i18n.locale === 'kh'">
                <li>
                  <a href="#" onclick="window.open('tnc-kh.pdf', 'Merchant Payment Service General Terms and Conditions','_blank', 'fullscreen=yes'); return false;">{{
                  $t(`Layout['${"使用者条款"}']`)
                }}</a>
                </li>
              </template>
              <template v-else-if="$i18n.locale === 'zh'">
                <li>
                  <a href="#" onclick="window.open('tnc-cn.pdf', 'Merchant Payment Service General Terms and Conditions','_blank', 'fullscreen=yes'); return false;">{{
                  $t(`Layout['${"使用者条款"}']`)
                }}</a>
                </li>
              </template>
              <template v-else>
                <li>
                  <a href="#" onclick="window.open('tnc-en.pdf', 'Merchant Payment Service General Terms and Conditions','_blank', 'fullscreen=yes'); return false;">{{
                  $t(`Layout['${"使用者条款"}']`)
                }}</a>
                </li>
              </template>
              <li>
                <router-link :to="{ path: '/privacy_policy'}">{{
                  $t(`Layout['${"隐私权条款"}']`)
                }}</router-link>
              </li>
              <li @click="showMapDialog">
                <a style="cursor: pointer">
                  {{ $t(`Layout['${"充值机位置"}']`) }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="right-box">
          <ul class="right-box-top">
            <li v-for="(item, i) in footerRightList.top" :key="i">
              <img :src="item.icon" />
              <p>{{ item.text }}</p>
            </li>
          </ul>
          <div class="right-box-bottom">
            <h3>{{ $t("Layout['社交媒体']") }}</h3>
            <a
              :href="item.href"
              target="_blank"
              v-for="(item, i) in footerRightList.botton"
              :key="i"
            >
              <img class="item-img" :src="item.icon" :alt="item.alt" />
            </a>
            <el-popover
              class="wechat-code"
              placement="top"
              width="200"
              trigger="hover"
            >
              <p style="font-size: 16px; margin-left: 10px">
                {{ $t(`Layout['优付微信公众号']`) }}
              </p>
              <img
                src="../assets/image/qrcode_for_wechat.jpg"
                alt="wechat code"
                style="width: 200px; height: 200px"
              />
              <img
                class="item-img"
                src="../assets/image/icon-wechat@2x.png"
                alt="wechat"
                slot="reference"
              />
            </el-popover>
          </div>
        </div>
      </div>
    </footer>

    <el-dialog :visible.sync="mapDialogVisible" class="map-iframe-diaglog">
      <div class="mapdialog-content" v-loading="mapIframeLoading">
        <iframe
          ref="mapIframe"
          src="https://www.google.com/maps/d/embed?mid=1LvasLQ9TMAi8vgyYGRx4s7www4KdfF87"
          width="100%"
          height="100%"
        ></iframe>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'layout',
  computed: {
    nav() {
      return [
        { name: '关于U-Pay', to: '/' },
        {
          name: '电子钱包',
          to: '/e-wallet',
          children: [
            { name: '成为用户', to: '/e-wallet#user' },
            { name: '邀请好友', to: '/e-wallet#invite' },
            { name: '使用说明', to: '/e-wallet#guide' }

            // { name: '优付商户', to: '/e-wallet/discover' }
          ]
        },
        // { name: '客户推荐', to: '/merchantstory' },
        // {
        //   name: '最新消息',
        //   to: '/news',
        //   children: [
        //     { name: '活动资讯', to: '/news#events' },
        //     { name: '媒体报导', to: '/news#press' }
        //   ]
        // },
        {
          name: '商户专区',
          to: '/merchants',
          children: [
            { name: '商户优势', to: '/merchants#benefit' },
            { name: '常见问题', to: '/merchants#FAQs' }
          ]
        },
        { name: '下载', to: '/download' },
        {
          name: '职业',
          to: '/career'
        }
      ]
    },
    footerLeftList() {
      return [
        [
          { name: '关于U-Pay', to: '/' },
          { name: '电子钱包', to: '/e-wallet' }
          // { name: '客户推荐', to: '/merchantstory' },
          // { name: '最新公告', to: '/news' }
        ]
        // [
        //   { name: '使用者条款', to: '/terms_of_service' },
        //   { name: '隐私权条款', to: '/privacy_policy' }
        // ]
      ]
    },
    footerRightList() {
      return {
        top: [
          {
            icon: require('@/assets/image/电话icon@2x.png'),
            text: '085-978-899 (ខ្មែរ /EN) ;  081-978-899 (ខ្មែរ /中)'
          },
          {
            icon: require('@/assets/image/邮箱icon@2x.png'),
            text: 'inquiry@u-pay.com'
          },
          {
            icon: require('@/assets/image/地址icon@2x.png'),
            text: this.$t("Layout['address']")
          }
        ],
        botton: [
          {
            icon: require('@/assets/image/icon-youtube@2x.png'),
            alt: 'youtube',
            href: 'https://www.youtube.com/channel/UCjNctXOMV9meVu9beuUhmzw'
          },
          {
            icon: require('@/assets/image/icon-facebook@2x.png'),
            alt: 'facebook',
            href: 'https://www.facebook.com/U-Pay-Digital-PLC-105689711232809'
          },
          {
            icon: require('@/assets/image/icon-instagram@2x.png'),
            alt: 'instagram',
            href: 'http://instagram.com/upay.kh?utm_source=qr'
          },
          {
            icon: require('@/assets/image/icon- linkedin@2x.png'),
            alt: 'linkedin',
            href: 'https://www.linkedin.com/company/u-pay-digital-plc'
          },
          {
            icon: require('@/assets/image/icon-telegram@2x.png'),
            alt: 'telegram',
            href: 'https://t.me/UPay_Cambodia'
          }
          // { icon: require('@/assets/image/icon-wechat@2x.png'), alt: 'wechat' }
        ]
      }
    },
    lang() {
      switch (this.$i18n.locale) {
        case 'zh':
          return '中文'
        case 'en':
          return 'English'
        case 'kh':
          return 'ខ្មែរ'
        default:
          this.changeLange('zh')
          return '中文'
      }
    },
    smollScreen() {
      return window.clientWidth < 1200
    }
  },
  data() {
    return {
      bgChange: true,
      menuShow: false,
      mapDialogVisible: false,
      mapIframeLoading: true
    }
  },
  mounted() {
    window.onscroll = () => {
      if (window.pageYOffset > 75) {
        this.bgChange = true
      } else {
        this.bgChange = true
      }
    }
    if (this.$i18n.locale === 'kh') {
      const body = document.querySelector('body')
      body.style.fontFamily =
        "'KhmerOS', 'Source Han Sans CN', 'microsoft yahei'"
      // body.style.wordBreak = 'break-all'
      // body.style.wordWrap = 'break-word'
    }
  },
  methods: {
    changeLange(lang) {
      this.$i18n.locale = lang
      if (lang === 'kh') {
        const body = document.querySelector('body')
        body.style.fontFamily =
          "'KhmerOS', 'Source Han Sans CN', 'microsoft yahei'"
      } else {
        const body = document.querySelector('body')
        body.style.fontFamily =
          "'Source Han Sans CN', 'microsoft yahei', 'KhmerOS'"
      }
      localStorage.setItem('locale', lang)
    },
    showMapDialog() {
      this.$data.mapDialogVisible = true
      this.$nextTick(() => {
        this.$refs.mapIframe.onload = () => {
          this.$data.mapIframeLoading = false
        }
      })
    }
  }
}
</script>
<style lang="less">
.menu-list {
  .el-drawer:focus {
    outline: none;
  }
  .el-menu {
    border: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .el-drawer__body {
    padding-top: 15px;
  }
  .el-drawer.rtl {
    width: 60% !important;
    .el-menu-item,
    .el-submenu__title {
      font-size: 15px;
      line-height: 50px;
      height: 50px;
      color: #333;
    }
    .el-submenu__icon-arrow {
      font-size: 12px;
    }
    .el-menu-item-group ul .el-menu-item {
      font-size: 14px;
    }
  }
}
</style>
<style lang="less" scoped>
@bacecolor: #e60013;
.header {
  position: fixed;
  z-index: 999;
  width: 100%;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.08);
  transition: all 0.1s;
  button {
    width: 80px;
    height: 34px;
    outline: none;
    background: none;
    margin-left: 20px;
    border: 1px solid #ffffff;
    border-radius: 6px;
    line-height: 34px;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      border-color: @bacecolor;
      background-color: @bacecolor;
      color: #fff;
      opacity: 0.8;
    }
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 74px;
    line-height: 1.5;
    color: #fff;
    .logo {
      height: 38px;
      width: 128px;
      background: url("../assets/image/顶部UPAY-logo@2x.png") no-repeat;
      background-size: contain;
      color: rgba(0, 0, 0, 0);
      line-height: 38px;
    }
    .nav {
      display: flex;
      margin-left: 20px;
      .menu {
        display: flex;
        .menu-item-link {
          text-align: center;
          height: 74px;
          display: flex;
          align-items: center;
          padding: 0 15px;
          color: #fff;
        }
        .menu-item {
          display: flex;
          position: relative;
          flex-direction: column;
          align-items: center;
          .submenu {
            box-sizing: border-box;
            overflow: hidden;
            border-radius: 0 0 8px 8px;
            position: absolute;
            top: 74px;
            flex-direction: column;
            height: 0;
            min-width: 100px;
            transition: all 0.3s;
            background: rgba(255, 255, 255, 0.3);
            box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
            .submenu-item {
              line-height: 50px;
              text-align: center;
              a {
                padding: 0 10px;
                height: unset;
                white-space: nowrap;
                color: #fff;
              }
              &:hover {
                background: rgba(253, 230, 231, 0.6);
                a {
                  color: #666;
                }
              }
            }
          }
          &:hover {
            z-index: 9999;
            .submenu {
              display: block;
              height: auto;
              padding: 10px 0 15px;
            }
          }
        }
      }
      .nav-line {
        position: absolute;
        bottom: 0px;
        width: 50px;
        height: 2px;
        background: #ffffff;
      }
      .fade-enter-active,
      .fade-leave-active {
        transition: width 0.3s;
      }
      .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        width: 0;
      }
      .buttons {
        display: flex;
        align-items: center;
        margin-left: 15px;
        /deep/.changeLang {
          white-space: nowrap;
          margin-left: 20px;
          .el-dropdown-link {
            cursor: pointer;
            color: #fff;
          }
        }
        .menu-icon {
          display: none;
          margin-left: 15px;
          font-size: 28px;
          vertical-align: middle;
          cursor: pointer;
          &:hover {
            color: #ffe6e7;
          }
        }
        @media screen and (max-width: 768px) {
          .menu-icon {
            display: inline-block;
          }
        }
      }
      @media screen and (max-width: 1200px) {
        .menu .menu-item-link {
          padding: 0 8px;
        }
        .buttons {
          margin-left: 0px;
        }
      }
      @media screen and (max-width: 992px) {
        .menu .menu-item-link {
          font-size: 13px;
          padding: 0 8px;
        }
        .buttons {
          margin-left: 0px;
        }
      }
    }
  }
}
.bgchange {
  background: #ffffff;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
  .container,
  a {
    color: #333333 !important;
  }
  .submenu {
    background: #ffffff !important;
  }
  .nav-line {
    background: #e60013 !important;
  }
  .logo {
    background-image: url("../assets/image/顶部changeUPAY-logo@2x.png") !important;
  }
  button {
    color: #333333;
    border-color: #333333;
    &:hover {
      color: #fff;
      border-color: #e60013;
      opacity: 0.8;
    }
  }
  /deep/.changeLang {
    .el-dropdown-link {
      color: #333333 !important;
    }
  }
}
.footer {
  background-color: #22211f;
  padding: 60px 0;
  font-size: 14px;
  color: hsl(45, 4%, 79%);
  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    a {
      transform: color 0.3s;
      &:hover {
        color: #fde6e7 !important;
      }
    }
    .footer-logo img {
      height: 38px;
    }
    .left-box-list {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
      ul {
        margin-right: 60px;
        margin-top: 20px;
        line-height: 36px;
        a {
          color: #cccbc8;
        }
      }
    }
    .right-box {
      .right-box-top {
        li {
          display: flex;
          margin-bottom: 13px;
          img {
            height: 30px;
            width: 30px;
            margin-right: 10px;
            border-radius: 50%;
            background: #393836;
          }
          p {
            margin-top: 5px;
            max-width: 349px;
            line-height: 20px;
          }
        }
        li:nth-child(3) p {
          margin-top: 0;
        }
      }
      .right-box-bottom {
        h3 {
          margin: 30px 0 12px;
        }
        .item-img {
          margin-right: 20px;
          margin-bottom: 20px;
          height: 46px;
          width: 46px;
          border-radius: 50%;
          background: #393836;
        }
        .wechat-code {
          cursor: pointer;
        }
      }
    }
  }
}
/deep/ .map-iframe-diaglog {
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 5px 9px 9px 5px;
    height: 80vh;
  }
  .el-dialog {
    margin-top: 8vh !important;
    width: 90vw;
    max-width: 1200px;
  }
  .mapdialog-content {
    height: 100%;
    width: 100%;
  }
}
</style>
