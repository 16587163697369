import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../layout'

Vue.use( VueRouter )

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      { path: '/', name: 'home', component: () => import( '@/views/Home.vue' ) },
      { path: '/e-wallet', name: 'eWallet', component: () => import( '@/views/EWallet' ) },
      { path: '/e-wallet/discover', name: 'eWallet-discover', component: () => import( '../views/EWallet/Discover.vue' ) },
      { path: '/merchantstory', name: 'Merchantstory', component: () => import( '@/views/MerchantStory' ) },
      { path: '/news', name: 'news', component: () => import( '@/views/LatestNews.vue' ) },
      { path: '/merchants', name: 'merchants', component: () => import( '@/views/Merchants.vue' ) },
      { path: '/download', name: 'Dowload', component: () => import( '@/views/Download.vue' ) },
      { path: '/search', name: 'Search', component: () => import( '@/views/Search.vue' ) },
      { path: '/career', name: 'Career', component: () => import( '@/views/Career.vue' ) },
      { name: 'Software Developer', path: '/career/software_developer', component: () => import( '@/views/JobDescriptions/SoftwareDeveloper.vue' ) },
      { name: 'Software Developer Intern', path: '/career/software_developer_intern', component: () => import( '@/views/JobDescriptions/SoftwareDeveloperIntern.vue') },
      { name: 'Accountant', path: '/career/accountant', component: () => import( '@/views/JobDescriptions/Account.vue' ) },
      { name: 'Product Specialist', path: '/career/product_specialist', component: () => import( '@/views/JobDescriptions/ProductSpecialist.vue' ) },
      { name: 'Digital Marketing Intern', path: '/career/digital_marketing_intern', component: () => import( '@/views/JobDescriptions/DigitalMarketingIntern.vue' ) },
      { name: 'Marketing Officer', path: '/career/marketing_officer', component: () => import( '@/views/JobDescriptions/MarketingOfficer.vue' ) },
      { name: 'Customer Service', path: '/career/customer_service', component: () => import( '@/views/JobDescriptions/CustomerService.vue' ) },
      { name: 'Merchant Relationship Officer', path: '/career/merchant_relationship_officer', component: () => import( '@/views/JobDescriptions/MerchantRelationship.vue' ) },
      { name: 'Partnership', path: '/career/partnership', component: () => import( '@/views/JobDescriptions/Partnership.vue' ) },
      { name: 'Risk & Compliance', path: '/career/senior_risk_&_compliance_officer', component: () => import( '@/views/JobDescriptions/RiskCompliance.vue' ) },
      { name: 'Data Analyst', path: '/career/data_analyst', component: () => import( '@/views/JobDescriptions/DataAnalyst.vue' ) },
      // * Career page update 21/06/2023 * //
      { name: 'Software Development Supervisor', path: '/career/software_development_supervisor', component: () => import( '@/views/JobDescriptions/SoftwareDevelopmentSupervisor.vue' ) },
      { name: 'Infrastructure Supervisor', path: '/career/infrastructure_supervisor', component: () => import( '@/views/JobDescriptions/InfrastructureSupervisor.vue' ) },
      { name: 'Sale Manager', path: '/career/sale_manager', component: () => import( '@/views/JobDescriptions/SaleManager.vue' ) },
      { name: 'Sale Executive', path: '/career/sale_executive', component: () => import( '@/views/JobDescriptions/SaleExecutive.vue' ) },
      { name: 'Operation Manager', path: '/career/operation_manager', component: () => import( '@/views/JobDescriptions/OperationManager.vue' ) },
      // * Career page update 29/08/2023 * //
      { name: 'IT Manager', path: '/career/it_manager', component: () => import( '@/views/JobDescriptions/ITManager.vue' ) },
      { name: 'POS Machine Sale Manager', path: '/career/pos_machine_sale_manager', component: () => import( '@/views/JobDescriptions/POSMachineSaleManager.vue' ) },
      { name: 'Finance Manager', path: '/career/finance_manager', component: () => import( '@/views/JobDescriptions/FinanceManager.vue' ) },
      { name: 'Marketing Manager', path: '/career/marketing_manager', component: () => import( '@/views/JobDescriptions/MarketingManager.vue' ) }
    ]
  },
  {
    path: '/privacy_policy',
    name: 'PrivacyPolicy',
    component: () => import( '../views/PrivacyPolicy.vue' )
  },
  // {
  //   path: '/TermsOfService',
  //   name: 'TermsOfService',
  //   component: () => import( '../views/TermsOfService.vue' )
  // },
  {
    path: '*',
    name: '404',
    component: () => import( '../views/404.vue' )
  }
]

const router = new VueRouter( {
  mode: 'history',
  scrollBehavior ( to, from, savedPosition ) {
    if ( to.hash ) {
      return {
        selector: to.hash
      }
    }
    if ( savedPosition ) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
} )

export default router
