import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueI18n from 'vue-i18n'
import 'element-ui/lib/theme-chalk/display.css'
import './styles/index.less'
import Penel from '@/components/Panel'
import { Loading, OptionGroup, MessageBox, Input, Select, Option, Button, FormItem, Form, Dialog, Collapse, CollapseItem, Dropdown, DropdownMenu, DropdownItem, Popover, Drawer, Menu, Submenu, MenuItemGroup, MenuItem, Table, TableColumn, Breadcrumb, BreadcrumbItem } from 'element-ui'

Vue.component( 'Penel', Penel )
// Vue.use(BootstrapVue)
// Vue.use(IconsPlugin)
Vue.use( VueI18n )
Vue.use( Dropdown )
Vue.use( DropdownMenu )
Vue.use( DropdownItem )
Vue.use( Popover )
Vue.use( Drawer )
Vue.use( Menu )
Vue.use( Submenu )
Vue.use( MenuItemGroup )
Vue.use( MenuItem )
Vue.use( Collapse )
Vue.use( CollapseItem )
Vue.use( Dialog )
Vue.use( Form )
Vue.use( FormItem )
Vue.use( Button )
Vue.use( Input )
Vue.use( Select )
Vue.use( Option )
Vue.use( OptionGroup )
Vue.use( Table )
Vue.use( TableColumn )
Vue.use( Breadcrumb )
Vue.use( BreadcrumbItem )
Vue.use( Loading )

Vue.prototype.$messageBox = MessageBox

const locale = localStorage.getItem( 'locale' ) || 'en'
const i18n = new VueI18n( {
  locale: [ 'zh', 'kh', 'en' ].includes( locale ) ? locale : 'en',
  defaultLocale: 'en',
  messages: {
    en: require( '@/locales/en.json' ),
    zh: require( '@/locales/zh.json' ),
    kh: require( '@/locales/kh.json' )
  }
} )
Vue.config.productionTip = false

new Vue( {
  router,
  i18n,
  mounted () {
    document.dispatchEvent( new Event( 'render-event' ) )
  },
  render: h => h( App )
} ).$mount( '#app' )
